import { Children, isValidElement, cloneElement, useCallback, useMemo, useRef, useState } from 'react';

import { useI18nContext, withI18n } from 'components/I18nProvider';
import NodeTokenSelector from 'components/NodeTokenSelector';
import Token from 'components/Token';
import { useTokensForSelectedNode } from 'store/tokens/hooks';

import { InsertTokenHandler, TokenTextAreaRef } from '../inputs/tokens/TokenTextArea';
import FieldGroup, { FieldGroupProps } from './FieldGroup';
import { isSingleTokenEligible } from './InputProxy/utils';
import { isValidSingleTokenValue } from './tokens/utils';

import './TokenizableFieldGroup.less';

export type PartialTokenTextAreaRef = TokenTextAreaRef;
export type InsertTokenCompatibleRef = Pick<TokenTextAreaRef, 'insertToken'>;
export type TokenizableFieldRef = TokenTextAreaRef | InsertTokenCompatibleRef;

export type TokenizableFieldGroupProps = {
  disableTokens?: boolean;
  /* used to catch token selections for fields that don't have an exposed insertToken method */
  fallbackOnTokenSelect?: InsertTokenHandler;
  hideTokenPicker?: boolean;
} & FieldGroupProps;

const TokenizableFieldGroup = ({
  children,
  disableTokens = false,
  labelSiblings,
  fallbackOnTokenSelect,
  helpText,
  hideTokenPicker,
  ...props
}: TokenizableFieldGroupProps) => {
  const { tn } = useI18nContext();
  const { getToken } = useTokensForSelectedNode();
  const field = useRef<TokenizableFieldRef | undefined>(undefined);

  const child = Children.only(children);
  const { datatype, value } = (child.props as unknown) as any;

  // editMode is used to determine if the user is actively editing an input, in
  // which case we don't show the Token (if there is one) until after the user
  // blurs the input
  const [editMode, setEditMode] = useState(false);

  const onTokenSelect: InsertTokenHandler = (token) => {
    if (field.current?.insertToken && !isSingleTokenEligible(datatype)) {
      field.current.insertToken(token);
    } else {
      fallbackOnTokenSelect?.(token);
    }
  };

  const registerField = useCallback((node) => {
    if (node) {
      field.current = node;
    }
  }, []);

  const labelSiblingsNode =
    disableTokens || hideTokenPicker ? (
      labelSiblings
    ) : (
      <>
        <NodeTokenSelector onTokenSelect={onTokenSelect} />
        {labelSiblings}
      </>
    );
  const isTokenValue = useMemo(() => isValidSingleTokenValue(value) && isSingleTokenEligible(datatype), [
    datatype,
    value,
  ]);

  const getInputNode = () => {
    if (!(child && isValidElement(child)) || typeof child === 'string' || disableTokens) {
      return child;
    }

    const { id, name, onChange, value } = (child.props as unknown) as any;

    if (isTokenValue && !editMode) {
      const token = getToken(value);
      const handleRemove = () => onChange?.('', name, id);

      return (
        <div className="synri-field-group-token-wrapper">
          <Token onRequestRemove={handleRemove} token={token} />
        </div>
      );
    }

    return cloneElement(child, {
      ref: registerField,
      onFocus: () => setEditMode(true),
      onBlur: () => setEditMode(false),
    } as any);
  };

  const helpTextOrTokenInfo = !disableTokens && isTokenValue ? tn('this_field_is_using_data_token') : helpText;

  return (
    <FieldGroup helpText={helpTextOrTokenInfo} labelSiblings={labelSiblingsNode} {...props}>
      {getInputNode()}
    </FieldGroup>
  );
};

export default withI18n(TokenizableFieldGroup, 'TokenizableFieldGroup');
