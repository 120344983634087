import AntCheckbox, {
  CheckboxChangeEvent as CheckboxChangeEvent_,
  CheckboxProps as AntCheckboxProps,
} from 'antd/lib/checkbox';
import cx from 'classnames';
import * as React from 'react';

import './Checkbox.less';

export type CheckboxChangeEvent = CheckboxChangeEvent_;

export interface CheckboxProps extends AntCheckboxProps {
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ className, children, ...props }) => (
  <div className={cx('synri-checkbox', className)}>
    <AntCheckbox {...props}>{children}</AntCheckbox>
  </div>
);

export default Checkbox;
