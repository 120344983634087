import cx from 'classnames';
import { useContext } from 'react';
import * as React from 'react';

import { HStack } from './layout';
import './InlineTabs.less';

export type InlineTabsContextShape = {
  selectedTab: string;
  onChange: (key: string) => void;
};

const InlineTabsContext = React.createContext<InlineTabsContextShape>({
  selectedTab: '',
  onChange: (key: string) => {
    /* noop */
  },
});

export type InlineTabsProps = {
  selectedTab: string;
  onChange: (key: string) => void;
  className?: string;
};

const InlineTabs: React.FC<InlineTabsProps> = ({ children, className, selectedTab, onChange }) => {
  return (
    <InlineTabsContext.Provider value={{ selectedTab, onChange }}>
      <div className={cx(className, 'synri-inline-tabs-container')}>
        <HStack spacing="z">{children}</HStack>
      </div>
    </InlineTabsContext.Provider>
  );
};

const useTab = (id: string) => {
  const { selectedTab, onChange } = useContext(InlineTabsContext);

  return {
    isCurrent: selectedTab === id,
    activateTab: () => onChange(id),
  };
};

type InlineTabProps = {
  id: string;
  className?: string;
  children: string | React.ReactNode;
};

const InlineTab = ({ id, className, children }: InlineTabProps) => {
  const { isCurrent, activateTab } = useTab(id);

  return (
    <div className={cx(className, 'synri-inline-tab', isCurrent && 'is-current')} role="button" onClick={activateTab}>
      {children}
    </div>
  );
};

export { InlineTabs, InlineTab, useTab };
