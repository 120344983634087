import cx from 'classnames';
import * as React from 'react';

import { Spacing } from '../types';

import './Divider.less';

interface DividerProps {
  className?: string;

  /** vertical spacing around divider */
  y?: Spacing;
}

export const Divider: React.FC<DividerProps> = ({ y = 'md', className }) => {
  return (
    <div className={cx('synri-divider', `synri-divider-${y}`, className)}>
      <div className="divider" />
    </div>
  );
};
